import React from 'react'
import Header from '@/components/Layout/Header'
import Footer from '@/components/Layout/Footer'
import Hero from '@/components/Layout/Hero'

export interface MainLayoutProps {
  /** Page content */
  children: React.ReactNode
  nav?: any
  hero?: any
  title?: string
  backPath?: string
}

/** Component shares layout structure between pages. Pass common sections like header, footer and content container here and wrap page components with it */
const Layout = ({ children, nav, hero, title, backPath }: MainLayoutProps) => {
  return (
    <>
      <Header nav={nav} />
      <main id="main" className="w-full main-height container mx-auto">
        <Hero
          title={hero?.title || title}
          archive={hero?.archive}
          disableBackbutton={hero?.disableBackButton}
          button={hero?.button}
          image={hero?.image}
          backPath={backPath}
        />
        <section className="px-8 lg:px-0">{children}</section>
      </main>
      <Footer />
    </>
  )
}

export default Layout
