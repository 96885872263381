import React from 'react'
import { graphql } from 'gatsby'
import Layout from '@/components/Layout/Layout'
import Seo from '@/components/Seo'
import NotFoundIcon from '@/images/404.png'

interface HomeData {
  data: {
    mainMenu?: any
  }
}

const NotFound = ({ data }: HomeData) => {
  // Page data
  const { mainMenu } = data

  return (
    <Layout nav={mainMenu}>
      <Seo title={'Sivua ei löytynyt'} />
      <section className="mx-auto justify-center items-center flex flex-col lg:flex-row gap-8 my-60 lg:-ml-48">
        <img
          className="-ml-10 md:-ml-20 xl:-ml-0 w-40 md:w-80"
          width="320"
          height="220"
          alt="Sivua ei löytynyt"
          src={NotFoundIcon}
        />
        <div className="grid place-content-center place-items-center">
          <h1 className="text-9xl">404</h1>
          <p className="text-2xl font-helvetica">Oho! Etsimääsi sivua ei löydy</p>
        </div>
      </section>
    </Layout>
  )
}

export const NotFoundQuery = graphql`
  query NotFound {
    mainMenu: wpMenu(slug: { eq: "main-menu" }) {
      ...navigation
    }
  }
`

export default NotFound
